import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FaEnvelope } from 'react-icons/fa';
import Select from 'react-select';
import * as Yup from 'yup';

import Loading from 'components/Loading';
import PageTitle from 'components/PageTitle';
import FormWrapper from 'components/FormWrapper';
import FormHeader from 'components/FormHeader';
import PanelButton from 'components/PanelButton';
import Error from 'components/Error';

import api from 'services/api';
import toast from 'services/toast';
import swal from 'services/swal';

import useSites from 'hooks/useSites';
import useAdditionalUsers from 'hooks/useAdditionalUsers';

import { Container } from './styles';

interface MatchParams {
  id: string;
}

type AdditionalUserEditProps = RouteComponentProps<MatchParams>;

interface EditAdditionalUserFormValues {
  user: string;
  type: string;
  key: string;
  sites: string[];
}

const Edit: React.FC<AdditionalUserEditProps> = ({ match }) => {
  const { t } = useTranslation();

  const chave = match.params.id.replace(
    /(.{8})(.{4})(.{4})(.{4})/,
    '$1-$2-$3-$4-',
  );

  const [error, setError] = useState(false);

  const { sites } = useSites();
  const { additionalUsers, isLoading, refetch } = useAdditionalUsers();

  type AdditionalUser = typeof additionalUsers[number];

  const [additionalUser, setAdditionalUser] = useState<AdditionalUser>({
    user: '',
    key: '',
    type: 'ADMINISTRADOR',
    registrationDate: '',
    sites: [],
  });

  const sitesOptions = sites.map(site => ({
    value: site.site,
    label: site.site,
  }));

  const selectedSites = sites
    .filter(site => {
      return additionalUser.sites.some(
        additionalUserSite => additionalUserSite.idSite === site.idSite,
      );
    })
    .map(site => ({
      value: site.site,
      label: site.site,
    }));

  useEffect(() => {
    setError(false);
    const findAdditionalUser = additionalUsers.find(user => user.key === chave);

    if (findAdditionalUser) {
      setAdditionalUser(findAdditionalUser);
    } else {
      setError(true);
    }
  }, [additionalUser, additionalUsers, chave]);

  async function editAdditionalUsers(values: EditAdditionalUserFormValues) {
    try {
      const { type } = values;

      await api.put(`painel/v1/usuario-adicional`, {
        chave,
        nivel: type,
        sites: values.sites,
      });

      refetch();

      toast.fire(t('pages:additionalUsersEdit.success'));
    } catch (err) {
      swal.fire({
        title: t('pages:additionalUsersEdit.failed'),
        html: err.response.data.error_description,
      });
    }
  }

  if (isLoading) {
    return <Loading />;
  }

  if (error || additionalUser.user === '') {
    return <Error />;
  }

  return (
    <Container>
      <PageTitle
        title={t('titles:additionalUsers.Edit.title')}
        description={t('titles:additionalUsers.description')}
        icon={<FaEnvelope color="#FFFFFF" size={24} />}
      />

      <FormWrapper>
        <FormHeader
          title={t('pages:additionalUsersEdit.title')}
          description={t('pages:additionalUsersEdit.description')}
        />
        <Formik
          validateOnMount
          enableReinitialize
          initialValues={{
            user: additionalUser.user,
            type: additionalUser.type,
            key: additionalUser.key,
            sites: selectedSites.map(site => site.value),
          }}
          validationSchema={Yup.object().shape({
            type: Yup.string().required(t('validations:requiredField')),
          })}
          onSubmit={editAdditionalUsers}
        >
          {props => (
            <Form onSubmit={props.handleSubmit}>
              <Card.Body className="fieldset">
                <Form.Group as={Row} controlId="additionalUsers.usuario">
                  <Form.Label column sm={2}>
                    {t('common:email')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      name="user"
                      plaintext
                      readOnly
                      value={props.values.user}
                    />
                    <Form.Control.Feedback type="invalid">
                      {props.errors.user}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="additionalUsers.type">
                  <Form.Label column sm={2} className="required">
                    {t('common:level')}
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="select"
                      name="type"
                      onChange={props.handleChange}
                      value={props.values.type}
                      disabled={props.isSubmitting}
                    >
                      <option key="ADMINISTRADOR" value="ADMINISTRADOR">
                        Administrador
                      </option>
                      <option key="TECNICO" value="TECNICO">
                        Técnico
                      </option>
                    </Form.Control>
                  </Col>
                </Form.Group>

                {sitesOptions.length > 0 && (
                  <Form.Group as={Row} controlId="additionalUser.sites">
                    <Form.Label column sm={2}>
                      {t('common:site', { count: 2 })}
                    </Form.Label>
                    <Col sm={10}>
                      <Select
                        type="select"
                        isMulti
                        isLoading={isLoading}
                        defaultValue={selectedSites}
                        name="sites"
                        onChange={(eventValue: typeof sitesOptions) => {
                          props.setFieldValue(
                            'sites',
                            eventValue.map(option => option.value),
                          );
                        }}
                        placeholder="Limitar acesso a sites"
                        options={sitesOptions}
                      />
                      <Form.Text className="text-muted">
                        Caso queira conceder acesso a todos os sites, deixe em
                        branco.
                      </Form.Text>
                    </Col>
                  </Form.Group>
                )}
              </Card.Body>

              <div className="border-top pt-2 pb-2 pl-3">
                <PanelButton
                  type="submit"
                  variant="primary"
                  className="mr-1"
                  size="sm"
                  disabled={
                    props.isSubmitting || !props.isValid || !props.dirty
                  }
                >
                  {props.isSubmitting ? t('common:sending') : t('common:send')}
                </PanelButton>
                <PanelButton
                  variant="secondary"
                  forwardedAs={Link}
                  to="/usuarios-adicionais"
                  disabled={props.isSubmitting}
                >
                  {t('common:label.back')}
                </PanelButton>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </Container>
  );
};

export default withRouter(Edit);
