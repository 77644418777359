import { useQuery } from 'react-query';

import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt/index';

import api from 'services/api';

type AdditionalUserItemResponse = {
  usuario: string;
  chave: string;
  tipo: 'ADMINISTRADOR' | 'TECNICO';
  cadastro: string;
  sites: {
    idSite: number;
    cadastro: string;
  }[];
};

type AdditionalUsersResponse = {
  data: AdditionalUserItemResponse[];
};

type AdditionalUser = {
  user: string;
  key: string;
  type: 'ADMINISTRADOR' | 'TECNICO';
  registrationDate: string;
  sites: {
    idSite: number;
    cadastro: string;
  }[];
};

const initialData: AdditionalUser[] = [];

function formatAdditionalUser(
  additionalUser: AdditionalUserItemResponse,
): AdditionalUser {
  return {
    user: additionalUser.usuario,
    key: additionalUser.chave,
    type: additionalUser.tipo,
    registrationDate: format(parseISO(additionalUser.cadastro), 'dd/MM/yyyy', {
      locale: pt,
    }),
    sites: additionalUser.sites,
  };
}

async function loadAdditionalUsers(): Promise<AdditionalUser[]> {
  const additionalUsersResponse = await api.get<AdditionalUsersResponse>(
    `/painel/v1/usuario-adicional`,
  );

  const additionalUsers = additionalUsersResponse.data.data.map(
    formatAdditionalUser,
  );

  return additionalUsers;
}

export default function useAdditionalUsers() {
  const { data, ...rest } = useQuery<AdditionalUser[]>(
    'additionalUsers',
    () => loadAdditionalUsers(),
    {
      refetchOnWindowFocus: false,
      staleTime: 86400000,
      retry: false,
    },
  );

  const additionalUsers = data || initialData;

  return {
    additionalUsers,
    ...rest,
  };
}
